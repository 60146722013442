@import '../theme';

.user-avatar-30 {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px;
  font-size: 16px;
}

.user-avatar-32 {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px;
  font-size: 16px;
}

.user-avatar-50 {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px;
  font-size: 28px;
}

.user-avatar-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.user-avatar-empty {
  border: 1px dashed $darkblue;
  background-color: $darkblue;
  color: #fff;
  text-align: center;
  justify-content: center;
}

.user-avatar-initial {
  background: $darkblue;
  color: #fff;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
