@import '../../scss/theme';

.header-wrapper {
  padding: 16px 16px 0 0;

  .btn-outlined {
    color: $darkblue;
    background: none;
    border: 1px solid $darkblue;
    &:hover {
      color: $darkblue;
      background: none;
      border: 1px solid $darkblue;
    }
  }
}

.header-toolbar {
  padding: 16px;
  border-radius: 10px;
  height: 80px;
  background-color: #031e3b;
}

.navbar-dark .navbar-toggler {
  border: none;
}
