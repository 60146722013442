@import '../../../scss/theme';

.navbar-top-custom {
  padding: 0;
  height: 100%;
  justify-content: flex-start;
}

.logo-d {
  height: 49px;
}

.btn-toggle-header,
.btn-toggle-header:hover {
  color: #c3c3c3;
  width: 48px;
}

.btn-toggle-header {
  position: relative;
  z-index: 5;

  .feather {
    width: 24px;
    height: 24px;
  }
}

.top-link-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.top-link-user,
.top-link-notification {
  display: flex;
  height: 100%;
  position: relative;
}

.message-welcome {
  color: #fff;
  font-weight: 700;
  font-size: 12.8px;
  margin-right: 10px;
  text-align: right;
}

.badge-top-link {
  position: relative;
  top: -10px;
}

.btn-top-nav {
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 10px;

  .feather {
    width: 24px;
    height: 24px;
    stroke-width: 2px;
  }

  .badge {
    position: absolute;
    transform: translate(-70%, 32%);
    border-radius: 5px;
    height: 16px;
    min-width: 16px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 10px;
    font-size: 10px;
  }
}

.btn-top-nav.active {
  color: #fff;
  background-color: $orange;
}

.btn-top-nav:not(.active):hover,
.btn-top-nav:not(.active):focus,
.btn-top-nav:not(.active):active {
  color: $orange;
  background-color: transparent;
}

.badge-info {
  background-color: $blue;
}

.btn-create-order {
  padding: 10px 15px;
  height: 100%;
  font-size: 14px;
  position: relative;
  z-index: 10;

  &.disabled {
    opacity: 0.5;
  }

  .feather {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    stroke-width: 2px;
  }
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    flex-direction: row;
  }

  .btn-create-order {
    width: 50px;

    .feather {
      margin-right: 0px;
    }
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: unset;
    align-items: center;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .btn-create-order {
    width: 50px;

    .feather {
      margin-right: 0px;
    }
  }
}
