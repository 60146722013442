@import '../theme';

.table-flex {
  padding: 10px;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  overflow: auto;

  .row-table-header {
    padding: 0 10px;

    .cell-table-tm {
      font-weight: bold;
      height: auto;
      font-size: 14px;
    }
  }

  .cell-table-tm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 25px;
  }

  .flex-row-table-body {
    padding: 10px;
    border-radius: 5px;

    &:nth-child(odd) {
      background: #f1f1f1;
    }
  }
}

.label-status {
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize;
  display: inline-block;

  &.label-status-paid {
    background-color: $blue;
    color: #fff;
    font-weight: bold;
  }

  &.label-status-complete {
    background-color: $blue;
    color: #fff;
    font-weight: bold;
  }

  &.label-status-completed {
    background-color: $blue;
    color: #fff;
    font-weight: bold;
  }

  &.label-status-unpaid,
  &.label-status-unpaid_refund,
  &.label-status-pending,
  &.label-status-on-hold {
    background-color: #ffc72b;
    color: #fff;
    font-weight: bold;
  }

  &.label-status-cancelled {
    background-color: $red;
    color: #fff;
    font-weight: bold;
  }

  &.label-status-primary {
    background-color: $blue;
    color: #fff;
    font-weight: bold;
  }

  &.label-status-danger {
    background-color: $red;
    color: #fff;
    font-weight: bold;
  }
}

.table {
  th,
  td {
    padding: 10px;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
  }
}
